import request from './request.js';
export function uploadFile(onUploadProgress){
    var url = `/mes/order/task/workcard/release-user2`;
    return request({
        method: 'post',
        url,
        onUploadProgress:(args)=>{
            if(onUploadProgress){
                onUploadProgress(args);
            }
            console.log('up', args);
        },
        onDowbloadProgress:(args)=>{
            console.log('load', args);
        }
    }, true); 
}