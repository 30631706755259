<template>
    <div class="mypage body index">
        <div class="inner-box">
            <!-- <van-empty :image="icon" description="尚未绑定,请刷工作证绑定" /> -->
            <div class="tip">
                <div>1、读取方式为NFC，请确保设备支持。</div>
                <div>2、请先点击读取按钮，再把工作证放到设备后面。</div>
            </div>
            <van-cell-group  v-if="info">
                <van-cell
                    style="min-height: 60px;"
                    title-class="cell-title"
                    value-class="flex-align-end cell-value">
                    <template slot="title">
                      <div class="flex-align cell-title">
                        <span class="">{{info.dingtalk.nickname}}</span>
                        <span>
                            <van-tag type="warning" style="margin-left: 8px;" v-if="card">已绑定</van-tag>
                            <van-tag type="warning" style="margin-left: 8px;" v-else>未绑定</van-tag>
                        </span>
                        
                      </div>
                    </template>
                    <template slot="label">
                        <div class="flex-align cell-label">
                          <span class="font-size2 bold">岗位：{{info.dingtalk.station}}</span>
                        </div>
                        <div class="flex-align cell-label">
                          <span class="font-size2 bold">工作证：{{card}}</span>
                        </div>
                    </template>
                    <template>
                        <!-- <van-icon name="scan" class="my-icon" 
                            color="#40a9ff" size="30" @click="scanCard" /> -->
                        <!-- <van-image
                          width="30"
                          height="30"
                          :src="changeIcon"
                          @click="scanCard"
                        /> -->
                        <van-button 
                            v-if="card"
                            color="#ff976a" size="small" @click="release">解&nbsp;&nbsp;绑</van-button>   
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <div>
            {{content}}
        </div>
        <div class="inner-box">
            <van-button style="margin: 0 0;" color="#1989fa" @click="scanCard" block>读取工作证</van-button>
        </div>
        <!-- <div class="inner-box">
            <van-button style="margin: 0 0;" color="#1989fa" @click="upload" block>上传文件</van-button>
        </div> -->
        
        <van-nav-bar
          title=""
          left-text="首页"
          right-text="去工作"
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
        />
    </div>
</template>

<script>
    import { getUser, bindUserCard, releaseUserCard} from '@/api/task.js';
    import { uploadFile } from '@/api/import.js';
    import { checkLogin } from '@/utils/common.js';
    import { Notify, Dialog } from 'vant';
    export default{
        data() {
            return{
                card: '' ,//'—',
                info: null,
                nfc: '../../assets/images/nfc.png',
                content: '',
                changeIcon: require('../../assets/images/shuaka.png'),
            }
        },
        
        activated() {
            this.getUser();
            this.hideTab();
        },
        methods:{
            upload(){
                uploadFile((p)=>{
                    console.log('进度', p);
                }).then((ret)=>{
                    console.log('完毕',ret);
                }).catch((err)=>{
                    console.log('err',err);
                })
            },
            getUser(){
                checkLogin().then(()=> {
                    getUser().then(({data})=> {
                        console.log('user', data);
                        this.info = data;
                        this.card = data.card_no;
                        
                    })
                })
            },
            release(){
                Dialog.confirm({
                    title: '提示',
                    message: '你确定要解绑吗',
                }).then(() => {
                    // 确认
                    var data = { uid: this.info.uid };
                    releaseUserCard(data).then(()=>{
                        Notify({ type: 'success', message: '解绑成功'});
                        this.getUser();
                        
                    }).catch((err)=> {
                        Notify({ type: 'warning', message: err.msg || err.message});
                    })
                });
            },
            scanCard(){
                var that = this;
                that.$dd.device.nfc.nfcRead({
                  onFail: (err) => {
                      var  {tagId} = err;
                        if(!tagId){
                            Notify({ type: 'warn', message: '未读取到工作证相关信息'});
                        }
                        
                        //var tagId = '14:be:44:61';
                        // 如果已绑定
                        var message = '';
                        // 未绑定
                        if(that.card){
                            if(tagId == that.card){
                               return Notify({ type: 'warning', message: '不可重复绑卡'});
                            }
                            message = `证件号${tagId}\n该员工已经绑定工作卡，确认重新绑定？`;
                        }else{
                            message = `证件号${tagId}`;
                        }
                        
                        Dialog.confirm({
                            title: '提示',
                            message: message,
                            confirmButtonText: '确认绑定',
                        }).then(() => {
                            // 确认
                            var data = { card: tagId };
                            bindUserCard(data).then(()=>{
                                Notify({ type: 'success', message: '成功'});
                                that.card = tagId;
                            }).catch((err)=> {
                                Notify({ type: 'warning', message: err.msg || err.message});
                            })
                        });
                  }
                });
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            onClickLeft(){
                this.$router.push('/');
            },
            onClickRight(){
                this.$router.push('/mes/order/task/0');
            },
        }
    }
</script>

<style scoped>
    .mypage{
        min-height: 100vh;
        background-color: #fff;
    }
    .inner-box{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .tip{
        font-size: 14px;
        color: #666666;
        font-weight: bold;
        padding: 15px;
    }
    .cell-title{
        font-size: 18px !important;
        /* color: #333 !important; */
        text-align: left;
        font-weight: bold;
        flex: 4 !important;
    }
    .cell-label{
        font-size: 16px !important;
        color: #666 !important;
        /* font-weight: bold; */
        margin-top: 8px;
    }
</style>